import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import Breakpoints from '../../themes/constants/breakpoints';
import logoFooter from '../../assets/Icons/logo_footer.svg';
import FooterMenu from './FooterMenu';
import { useServiceQuery } from '../../generated/graphql';
import { PATH_PREFIX } from '../../routes/routes';
import LanguageContext from '../../context/language';

const FooterWrapper = styled.div`
    width: 100%;
    min-height: 145px;
    background-color: #2d2d2d;
    border-radius: 10px 10px 0 0;
    padding: 10px 20px 20px;
    margin: 0 auto;
    box-sizing: border-box;
    z-index: 1;
    a {
        color: inherit;
        :active, :visited, :hover {
            color: inherit;
        }
    }
    @media only screen and (${Breakpoints.TABLET}) {
        padding: 25px;
    }
`;

const FooterInner = styled.div`
    max-width: ${({ theme }) => theme.maxWidth};
    width: 100%;
    height: 100%;
    font-size: 14px;
    color: #e4e4e4;
    text-align: justify;
    margin: 0 auto;
    gap: 15px;
    display: flex;
    flex-direction: column;
    a {
        color: inherit;
        :active, :visited, :hover {
            color: inherit;
        }
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        display: flex;
        flex-direction: row;
        gap: 0;
    }
`;

const Info = styled.div`
`;

const LogoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    @media only screen and (${Breakpoints.TABLET}) {
        justify-content: center;
    }
    @media only screen and (${Breakpoints.LAPTOP}) {
        justify-content: flex-start;
    }
`;

const Logo = styled.img`
    margin-right: 20px;
    @media only screen and (${Breakpoints.LAPTOP}) {
        margin-right: 12px;
    }
`;

const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media only screen and (${Breakpoints.LAPTOP}) {
        flex: 1;
        margin-right: 45px;
    }
`;

const RightSide = styled.div`
    display: flex;
    flex-direction: column;
    @media only screen and (${Breakpoints.LAPTOP}) {
        flex: 1;
        gap: 15px;
    }
`;

const Footer = () => {
    const { lang } = useContext(LanguageContext);
    const { data } = useServiceQuery({ fetchPolicy: 'no-cache', variables: { lang: [lang] } });
    const [state, setState] = useState({
        top: '',
        bottom: '',
    });

    useEffect(() => {
        axios.get(`${PATH_PREFIX}/text/uz-beeline-health/footer-nosubscribe-health/${lang === 'uz' ? 2 : 1}`)
            .then((res) => {
                if (res.data) {
                    setState((st) => ({ ...st, top: res.data }));
                }
            })
            .catch(() => {});
        axios.get(`${PATH_PREFIX}/text/uz-beeline-health/footer-under/${lang === 'uz' ? 2 : 1}`)
            .then((res) => {
                if (res.data) {
                    setState((st) => ({ ...st, bottom: res.data }));
                }
            })
            .catch(() => {});
    }, [lang]);

    return (
        <FooterWrapper>
            <FooterInner>
                <LeftSide>
                    <Info dangerouslySetInnerHTML={{ __html: state.top }} />
                </LeftSide>
                <RightSide>
                    {data?.point?.service.categories && <FooterMenu categories={data.point.service.categories} />}
                    <LogoWrapper>
                        <Logo src={logoFooter} alt="" />
                    </LogoWrapper>
                </RightSide>
            </FooterInner>
        </FooterWrapper>
    );
};

Footer.defaultProps = {
    data: undefined,
};

export default Footer;
